import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {userSave,userSaveResponse,userList,userUpdate,userUpdateResponse} from 'Redux/Registration/reg.actions'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Input from 'components/Input'
import PhoneFormat from 'components/PhoneFormat'
import CustomButton from 'components/CustomButton/CustomButton'
import Dropdown from 'components/Dropdown'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useForm } from 'Hooks/validator'
import toast from 'react-hot-toast'

const roles = [
  { value: 2, name: 'Front Desk' },
  { value: 3, name: 'Nurse Practitioner' },
]

function UserForm(props) {
  const defaultValues = {
    userId:0,
    clinicId:localStorage.getItem('cid'),
    firstName: '',
    lastName: '',
    middleName: '',
    roleId: 2,
    phone: '',
    email: '',
    userName: '',
    password: '',
    active: 1,
    isAdministratorAccess:false
  }
  const customErrorAttribute = {
    className: 'has-error',
    'another-attr': 'look-at-me',
    error: true,
    helperText: 'This field is required',
  }
  const { values, setValues, isValid, useInput, setIsResetform } = useForm(
    defaultValues,
    customErrorAttribute,
  )

  const handleSubmit = (e) =>{
   e.preventDefault()
   if(isValid){
    if(values.userId===0){
      props.userSave({...values})
    }else{
      props.userUpdate({...values})
    }
   }
  }

  useEffect(()=>{
  if(props.regData.userResponse&&props.regData.userResponse.response){
    const response = props.regData.userResponse.response
    if(response.responseCode===0){
      props.handleClose()
      toast.success('User added successfully')
      props.userSaveResponse(null)
      props.userList()
    }

  }
  },[props.regData.userResponse])

  useEffect(()=>{
    if(props.regData.userUpdateResponse&&props.regData.userUpdateResponse.response){
      const response = props.regData.userUpdateResponse.response
      if(response.responseCode===0){
        props.handleClose()
        toast.success('User updated successfully')
        props.userUpdateResponse(null)
        props.userList()
      }
  
    }
    },[props.regData.userUpdateResponse])


  useEffect(()=>{
    if(props.editValue){
     setValues({...values,...props.editValue})
    }
     },[props.editValue])

  const handleCheckbox = (e) =>{
     setValues({...values,isAdministratorAccess:e.target.checked})
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Input
              required
              id={'firstName'}
              tabIndex={1}
              type="text"
              label="First Name"
              {...useInput('firstName', {
                isRequired: {
                  value: true,
                  message: 'Enter a valid name',
                },
                isLength: {
                  value: { max: 100 },
                  message: 'Max Length is 100',
                },
                matches: {
                  value: /^[a-zA-Z][a-zA-Z -]+$/,
                  message: 'Enter the valid name',
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              // required
              id={'middleName'}
              tabIndex={2}
              type="text"
              label="Middle Name"
              {...useInput('middleName', {
                // isRequired: {
                //   value: true,
                //   message: 'Enter a valid name',
                // },
                isLength: {
                  value: { max: 100 },
                  message: 'Max Length is 15',
                },
                matches: {
                  value: /^[a-zA-Z][a-zA-Z -]+$/,
                  message: 'Enter the valid name',
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              required
              id={'lastName'}
              tabIndex={2}
              type="text"
              label="Last Name"
              {...useInput('lastName', {
                isRequired: {
                  value: true,
                  message: 'Enter a valid name',
                },
                isLength: {
                  value: { max: 100 },
                  message: 'Max Length is 100',
                },
                matches: {
                  value: /^[a-zA-Z][a-zA-Z -]+$/,
                  message: 'Enter the valid name',
                },
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Dropdown
              required
              id={'role'}
              tabIndex={3}
              options={roles}
              label="Role"
              {...useInput('roleId', {
                isRequired: {
                  value: true,
                  message: 'Please select a role',
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PhoneFormat
              id={'phone'}
              tabIndex={13}
              type="text"
              label="Phone"
              placeholder="(555) 555-5555"
              {...useInput('phone', {
                matches: {
                  value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                  message: 'Enter a valid phone number',
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              id={'email'}
              tabIndex={15}
              type="email"
              label="Email"
              {...useInput('email', {
                matches: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Enter a valid email',
                },
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Input
              required
              id={'userName'}
              tabIndex={1}
              type="text"
              label="User Name"
              {...useInput('userName', {
                isRequired: {
                  value: true,
                  message: 'Enter a valid Username',
                },
                isLength: {
                  value: { min: 6, max: 20 },
                  message: 'Username must be between 6 and 20 characters',
                },
              })}
            />
            {/* {userNameError === 4 ? (
              <Typography className={'suggestionLink'}>
                Suggestions:
                {suggestions.length > 0
                  ? suggestions.map((x, i) => (
                      <Box component="span" m={1}>
                        <Link href="#" onClick={() => clickSuggestions(i, x)}>
                          {x}
                        </Link>
                      </Box>
                    ))
                  : ''}
              </Typography>
            ) : (
              ''
            )} */}
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
            //   hidden={values.keycloakUserId ? true : false}
            >
              <Input
                required={values.userId?false:true}
                id={'password'}
                tabIndex={2}
                type="text"
                label="Password"
                {...useInput('password', {
                  isLength: {
                    value: { min: 6, max: 15 },
                    message: 'Password must be between 6 and 15 characters',
                  },
                  matches: {
                    value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{3,100}$/,
                    message:
                      'Enter a valid password with at least 1 digit, 1 letter and 1 character',
                  },
                })}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox color="primary" onChange={(e)=>handleCheckbox(e)} checked={values.isAdministratorAccess}/>}
              label="Administrative Privileges"
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>

        <div className="form__btnWrapper">
          <CustomButton
            name="Cancel"
              onClick={()=>props.handleClose()}
            default
          />
          <CustomButton
            name="Submit"
            type="submit"
            // onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  regData: state.reg,
})

const mapDispatchToProps = (dispatch) => ({
  userSave: (values) => dispatch(userSave(values)),
  userSaveResponse:(values) => dispatch(userSaveResponse(values)),
  userUpdate:(values) => dispatch(userUpdate(values)),
  userUpdateResponse:(values) => dispatch(userUpdateResponse(values)),
  userList:() => dispatch(userList()),
})
UserForm.propTypes = {
  userSave: PropTypes.func,
  userSaveResponse: PropTypes.func,
  userUpdate: PropTypes.func,
  userUpdateResponse: PropTypes.func,
  userList: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(UserForm)

