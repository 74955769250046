import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { apmtList, aptDelete } from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/CustomButton/CustomButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import AppointmentTypeForm from "./AppointmentTypeForm";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./DeleteDialog";
import { toast } from "react-hot-toast";

const useStyles = makeStyles(() => ({
  editBtn: {
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 14,
    "&:hover": {
      color: "#39BD65",
    },
  },
  editBtn2: {
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 14,
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    color: "#8792a2",
  },
}));

function AppointmentType(props) {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [aptId, setAptId] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  /* state for 'Add an AppointmentType' modal */
  const [openModal, setOpenModal] = useState(false);
  const [apmtlists, setApmtlist] = useState([]);
  const [selectedAppt, setSelectedAppt] = useState(null);
  const [mandatory, setMandatory] = useState(true);
  const handleOpenModal = () => {
    setOpenModal(true);
    setSelectedAppt(null);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  /******************************* */
  useEffect(() => {
    props.apmtList();
  }, []);

  useEffect(() => {
    if (
      props.regData.apmtlistResponse &&
      props.regData.apmtlistResponse.response
    ) {
      const data = props.regData.apmtlistResponse.response.data;
      if (data && data.results.length > 0) {
        setApmtlist(data.results);
        setMandatory(true);
        data.results.forEach((x) => {
          if (x.name && x.patientRequired && x.duration) {
          } else {
            setMandatory(false);
          }
        });
      }
    }
  }, [props.regData.apmtlistResponse]);

  const handleEdit = (x) => {
    handleOpenModal();
    setSelectedAppt(x);
  };
  const handleDelete = (id) => {
    setDeleteDialog(true);
    setAptId(id);
  };
  useEffect(() => {
    if (
      props.regData.aptDeleteResponse &&
      props.regData.aptDeleteResponse.response
    ) {
      const response = props.regData.aptDeleteResponse.response;
      if (response.responseCode === 0) {
        toast.success("Appointment type deleted successfully");
        let data = response.data;
        let newapt = [];
        apmtlists.forEach((x) => {
          if (x.id !== data.id) {
            newapt.push(x);
          }
        });
        setApmtlist(newapt);
        setDeleteDialog(false);
        setOpenModal(false);
      }
    }
  }, [props.regData.aptDeleteResponse]);
  return (
    <>
      <div className="container">
        <div className="container__header">
          <Typography variant="h6">Appointment Type</Typography>
          <CustomButton
            name="Add New Appointment Type"
            onClick={() => handleOpenModal()}
          />
        </div>

        <div className="container__listItems p-sm">
          {apmtlists &&
            apmtlists.map((x) => (
              <div className="container__listItem">
                <div className="container__listItemDetails">
                  <Typography className={classes.title}>{x.name}</Typography>
                  <Typography className={classes.subtitle}>
                    {x.duration} min
                  </Typography>
                </div>
                <div className="icon_delete_verify">
                  {x.name && x.patientRequired && x.duration ? (
                    <Chip
                      icon={<VerifiedUserIcon />}
                      label="Edit"
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(x)}
                    />
                  ) : (
                    <Chip
                      label="Verify"
                      size="small"
                      color="secondary"
                      onClick={() => handleEdit(x)}
                    />
                  )}
                  <div
                    title="Delete"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(x.id)}
                  >
                    <DeleteIcon color="secondary" />
                  </div>
                </div>
              </div>
            ))}
        </div>

        <Dialog open={openModal} maxWidth="lg">
          <div className="p-sm">
            <DialogTitle>Appointment Type</DialogTitle>
            <DialogContent>
              <AppointmentTypeForm
                editValue={selectedAppt}
                handleClose={handleClose}
              />
            </DialogContent>
          </div>
        </Dialog>
      </div>
      <div className="width-100 center space-between gap-xs forms_margin">
        <CustomButton
          name="Back"
          onClick={() => history.push("/location")}
          default
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <CustomButton
              disabled={!mandatory}
              name={"Finish"}
              onClick={() => history.push("/completed")}
            />
          </Grid>
        </Grid>
        <DeleteDialog
          deleteDialog={deleteDialog}
          handleCloseDelete={() => setDeleteDialog(false)}
          triggerDelete={() => props.aptDelete(aptId)}
        />
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  apmtList: (values) => dispatch(apmtList(values)),
  aptDelete: (values) => dispatch(aptDelete(values)),
});
AppointmentType.propTypes = {
  apmtList: PropTypes.func,
  aptDelete: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentType);
