import { useDropzone } from "react-dropzone";
import "../../GlobalStyles.css";
import React, { useState, useCallback, useMemo } from "react";
import CsvUploadIcon from "../../assets/csv-icon.svg";
import CloseIconRounded from "../../assets/close-icon-rounded.svg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/CustomButton/CustomButton";
import Dropdown from "components/Dropdown";

const useStyles = makeStyles({
  dropzoneContainer: {
    margin: "16px 0",
  },
  h6: {
    fontSize: "1.2rem !important",
    textAlign: "center",
  },
  fileListItem: {
    border: "1px solid #D7E0E9",
    background: "#F2F2F2",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    borderRadius: "8px",
    padding: "8px",
    // cursor: 'pointer',
  },
  fileListItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "16px 0",
  },
  buttonDiv: {
    display: "flex",
    // width: "250px",
  },
  emrdiv: {
    width: "173px",
    marginRight: "10px",
  },
});

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  // backgroundColor: 'rgba(208, 239, 239, 0.27)',
  color: "var(--color-grey-900)",
  fontWeight: "500",
  transition: "border .3s ease-in-out",
  borderRadius: "6px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "var(--Secondary-green)",
  backgroundColor: "rgba(208, 239, 239, 0.8)",
};

const rejectStyle = {
  borderColor: "rgba(255,85, 85, 0.7)",
  backgroundColor: "rgba(255,0, 13, 0.03)",
};

function FileUploaderDropzone(props) {
  const [files, setFiles] = useState([]);
  const [emrValue, setEmrValue] = useState("");
  const [emrName, setEmrName] = useState("");
  const [emrError, setEmrError] = useState(false);
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // accept: { 'text/csv': ['.csv'] },
    minSize: 0,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const triggerUpload = () => {
    if (emrValue !== "") {
      props.fileUploadfn(files, emrValue, emrName);
      setEmrError(false);
    } else {
      setEmrError(true);
    }
  };
  const setEmrValues = (e) => {
    let obj = props.emrArray.find((x) => x.value === e.target.value);
    if (obj) {
      console.log(obj);
      setEmrName(obj.name);
      setEmrValue(e.target.value);
      setEmrError(false);
    }
  };

  return (
    <div className={classes.dropzoneContainer}>
      <div className={classes.emrDiv}></div>

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <img src={CsvUploadIcon} alt="" className="icon" />
        {/* <p>Drag and drop files here or click to select files</p> */}
        {!isDragActive && (
          <div>
            <Typography className={classes.h6} variant="h6">
              Drag file here
            </Typography>
            <Typography className={classes.h6} variant="h6">
              or
            </Typography>
            <Typography className={classes.h6} variant="h6">
              <span className="link">Select file</span>
            </Typography>
          </div>
        )}
        {isDragActive && !isDragReject && "Drop the file here"}
        {isDragReject && "File type not accepted! Please upload a .CSV file."}
      </div>
      {files.length ? (
        <div className={classes.fileListItemContainer}>
          {files.length > 0 &&
            files.map((file, i) => (
              <div key={i} className={classes.fileListItem}>
                <Typography>{file.name}</Typography>
                <img
                  src={CloseIconRounded}
                  alt=""
                  style={{ color: "#000000", cursor: "pointer" }}
                  onClick={() => setFiles([])}
                />
              </div>
            ))}
          <div className={classes.buttonDiv}>
            <div className={classes.emrdiv}>
              <Dropdown
                error={emrError}
                helperText={emrError && "Please choose the type"}
                tabIndex={3}
                options={props.emrArray}
                label="EMR Type"
                autofocus
                onChange={setEmrValues}
                value={emrValue}
              />
            </div>
            <CustomButton
              type="submit"
              name={"Upload"}
              onClick={triggerUpload}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default FileUploaderDropzone;
