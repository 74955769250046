import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import {userList} from 'Redux/Registration/reg.actions'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CustomButton from 'components/CustomButton/CustomButton'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'

import UserForm from './UserForm'

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontWeight: 500,
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      color: '#39BD65',
    },
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    color: '#8792a2',
  },
}))

function Users(props) {
  const classes = useStyles()

  /* state for 'Add a User' modal */
  const [openModal, setOpenModal] = useState(false)
  const [users,setUsers] = useState([])
  const [selectedUser,setSelectedUser]= useState(null)
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setOpenModal(false)
  }
  /******************************* */

  useEffect(()=>{
    props.userList()
  },[])

  useEffect(()=>{
    if(props.regData.userList&&props.regData.userList.response){
     const data = props.regData.userList.response.data
     if(data&&data.results.length>0){
      setUsers(data.results)
     }
    }
  },[props.regData.userList])


  const handleEdit = (x) =>{
    handleOpenModal()
    setSelectedUser(x)
  }

  return (
    <>
      <div className="container">
        <div className="container__header">
          <Typography variant="h6">Users</Typography>
          <CustomButton
            includeIcon={true}
            name="Add New User"
            onClick={handleOpenModal}
          />
        </div>

        <div className="container__listItems p-sm">
         {users&&users.map(x=>(
          <div className="container__listItem">
            <div className="container__listItemDetails">
              <Typography className={classes.title}>
              {x.firstName+' '+x.lastName}
              </Typography>
              <Typography className={classes.subtitle}>{x.roleId===1?'Admin':x.roleId===2?'Front Desk':x.roleId===3?'Nurse Practitioner':""}</Typography>
            </div>
            <Typography
              color="primary"
              className={classes.editBtn}
              onClick={()=>handleEdit(x)}
            >
              Edit Details
            </Typography>
          </div>
          ))}
        </div>

        <Dialog open={openModal}  maxWidth="lg">
          <div className="p-sm">
            <DialogTitle>User</DialogTitle>
            <DialogContent>
              <UserForm editValue={selectedUser} handleClose={handleClose} />
            </DialogContent>
          </div>
        </Dialog>
      </div>
      <div className="width-100 center right gap-xs forms_margin">
        <CustomButton
          name="Back"
          disabled={props.activeStep === 0}
          onClick={props.handleBack}
          default
        />
        <CustomButton
          // onClick={users&&users.length>0&&props.handleNext}
          onClick={props.handleNext}
          name={props.activeStep === props.steps.length - 1 ? 'Finish' : 'Next'}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  regData: state.reg,
})

const mapDispatchToProps = (dispatch) => ({
  userList:() => dispatch(userList()),
})
Users.propTypes = {
  userList: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(Users)