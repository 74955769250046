import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { providerList, providerDelete } from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/CustomButton/CustomButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ProviderForm from "./ProviderForm";
import Grid from "@material-ui/core/Grid";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./DeleteDialog";
import { toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 14,
    "&:hover": {
      color: "#39BD65",
    },
  },
  editBtn2: {
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 14,
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    color: "#8792a2",
  },
  dialog: {
    minHeight: "500px",
  },
}));

function Providers(props) {
  const classes = useStyles();
  const history = useHistory();
  /* state for 'add a provider' modal */
  const [openModal, setOpenModal] = useState(false);
  const [providers, setProviders] = useState([]);
  const [mandatory, setMandatory] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [userId, setUserId] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    setSelectedProvider(null);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  /******************************* */
  useEffect(() => {
    props.providerList();
  }, []);

  useEffect(() => {
    if (props.regData.providerList && props.regData.providerList.response) {
      const data = props.regData.providerList.response.data;
      if (data && data.results.length > 0) {
        setProviders(data.results);
        setMandatory(true);
        data.results.forEach((x) => {
          if (x.credential && x.taxonomy && x.npi) {
          } else {
            setMandatory(false);
          }
        });
      }
    }
  }, [props.regData.providerList]);

  const handleEdit = (x) => {
    handleOpenModal();
    setSelectedProvider(x);
  };
  useEffect(() => {
    if (
      props.regData.providerDeleteResponse &&
      props.regData.providerDeleteResponse.response
    ) {
      const response = props.regData.providerDeleteResponse.response;
      if (response.responseCode === 0) {
        toast.success("Provider deleted successfully");
        let data = response.data;
        let newpro = [];
        providers.forEach((x) => {
          if (x.userId !== data.id) {
            newpro.push(x);
          }
        });
        setProviders(newpro);
        setOpenModal(false);
        setDeleteDialog(false);
      }
    }
  }, [props.regData.providerDeleteResponse]);
  const handleDelete = (id) => {
    setDeleteDialog(true);
    setUserId(id);
  };

  return (
    <>
      <div className="container">
        <div className="container__header">
          <Typography variant="h6">Provider</Typography>
          <CustomButton
            includeIcon={true}
            name="Add New Provider"
            onClick={() => handleOpenModal()}
          />
        </div>

        <div className="container__listItems p-sm">
          {providers &&
            providers.map((x) => (
              <div className="container__listItem">
                <div className="container__listItemDetails">
                  <Typography className={classes.title}>
                    {x.firstName + " " + x.lastName}
                  </Typography>
                  <Typography className={classes.subtitle}>
                    {x.speciality}
                  </Typography>
                </div>
                <div className="icon_delete_verify">
                  {x.credential && x.npi && x.taxonomy ? (
                    <Chip
                      icon={<VerifiedUserIcon />}
                      label="Edit"
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(x)}
                    />
                  ) : (
                    <Chip
                      label="Verify"
                      size="small"
                      color="secondary"
                      onClick={() => handleEdit(x)}
                    />
                  )}
                  <div
                    title="Delete"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(x.userId)}
                  >
                    <DeleteIcon color="secondary" />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Dialog open={openModal} maxWidth="lg" className={classes.dialog}>
          <div className="p-sm">
            <DialogTitle>Add a new Provider</DialogTitle>
            <DialogContent>
              <ProviderForm
                editValue={selectedProvider}
                handleClose={handleClose}
              />
            </DialogContent>
          </div>
        </Dialog>
      </div>
      <div className="width-100 center space-between gap-xs forms_margin">
        <CustomButton
          name="Back"
          onClick={() => history.push("/upload")}
          default
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          {/* <Grid item>
						<CustomButton
							name='Skip'
							onClick={() => history.push('/providers')}
							default
						/>
					</Grid> */}
          <Grid item>
            <CustomButton
              disabled={!mandatory}
              onClick={() => history.push("/location")}
              name={"Next"}
            />
          </Grid>
        </Grid>
      </div>
      <DeleteDialog
        deleteDialog={deleteDialog}
        handleCloseDelete={() => setDeleteDialog(false)}
        triggerDelete={() => props.providerDelete(userId)}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  providerList: () => dispatch(providerList()),
  providerDelete: (values) => dispatch(providerDelete(values)),
});
Providers.propTypes = {
  providerList: PropTypes.func,
  providerDelete: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Providers);
