import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import TrilliumLogo from "../../assets/trillium-logo.svg";
import "../../GlobalStyles.css";
import "./style.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  return (
    <div
      className={
        scrolled ? "header_bar sticky showShadow " : "header_bar sticky"
      }
    >
      <div className="header_logo_wrap">
        <img src={TrilliumLogo} alt="logo" className="header_logo" />
      </div>

      <div className="header_items_wrap">
        <div className="header_item">
          <a
            className="header_link"
            href="https://trillium.health/what-we-do.html"
            target="_blank"
          >
            What we do
          </a>
        </div>
        <div className="header_item">
          <a
            className="header_link"
            href="https://trillium.health/pricing.html"
            target="_blank"
          >
            Pricing
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
