import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
const DeleteDialog = ({ deleteDialog, handleCloseDelete, triggerDelete }) => {
  return (
    <div>
      <Dialog
        open={deleteDialog}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this type?
        </DialogTitle>

        <DialogActions>
          <Button onClick={triggerDelete} color="primary" variant="contained">
            Yes
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DeleteDialog;
