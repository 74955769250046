import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { PrivateRoute } from "Utils/private-route";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
// import { makeStyles } from '@material-ui/core/styles'
import ClinicDetails from "components/StepperContent/ClinicDetails/ClinicDetails";
import VerifyUser from "components/StepperContent/VerifyUser";
import Providers from "components/StepperContent/ProviderDetails/Providers";
import Users from "components/StepperContent/UserDetails/Users";
import Location from "components/StepperContent/LocationDetails/Location";
import AppointmentType from "components/StepperContent/AppointmentTypeDetails/AppointmentType";
import ProviderLocation from "components/StepperContent/ProviderLocation/ProviderLocation";
import UploadAppointment from "components/StepperContent/UploadAppointment/UploadAppointment";
import Finish from "components/Finish";
import TickIcon from "../../assets/tick-icon.svg";
import MobileView from "components/MobileView";

// import CustomButton from 'Components/CustomButton/CustomButton'

// const useStyles = makeStyles({
//   button: {
//     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//     border: 0,
//     borderRadius: 3,s
//     color: 'white',
//     height: 48,
//     padding: '0 30px',
//   },
// })

function getSteps() {
  return [
    "Verify",
    "Upload",
    "Providers",
    "Clinic Location",
    "Appointment Type",
  ];
}

export default function HorizonalStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [url, setUrl] = useState(null);
  // const [skipped, setSkipped] = useState(new Set())
  const steps = getSteps();

  // function getStepContent(step) {
  // 	switch (step) {
  // 		case 0:
  // 			return (
  // 				<ClinicDetails
  // 					activeStep={activeStep}
  // 					handleNext={handleNext}
  // 					handleBack={handleBack}
  // 					steps={steps}
  // 				/>
  // 			);
  // 		case 1:
  // 			return <UploadAppointment />;
  // 		case 2:
  // 			return (
  // 				<Providers
  // 					activeStep={activeStep}
  // 					handleNext={handleNext}
  // 					handleBack={handleBack}
  // 					steps={steps}
  // 				/>
  // 			);
  // 		case 3:
  // 			return (
  // 				<Location
  // 					activeStep={activeStep}
  // 					handleNext={handleNext}
  // 					handleBack={handleBack}
  // 					steps={steps}
  // 				/>
  // 			);
  // 		case 4:
  // 			return (
  // 				<AppointmentType
  // 					activeStep={activeStep}
  // 					handleNext={handleNext}
  // 					handleBack={handleBack}
  // 					steps={steps}
  // 				/>
  // 			);
  // 		default:
  // 			return 'Unknown step';
  // 	}
  // }

  // const isStepSkipped = (step) => {
  //   return skipped.has(step)
  // }

  const handleNext = (e) => {
    // let newSkipped = skipped
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values())
    //   newSkipped.delete(activeStep)
    // }

    setActiveStep(e);
    // setSkipped(newSkipped)
    if (activeStep === 5) {
      // localStorage.clear();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     You probably want to guard against something like this,
  //     it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.")
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1)
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values())
  //     newSkipped.add(activeStep)
  //     return newSkipped
  //   })
  // }

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  useEffect(() => {
    let url = window.location.href.split("/").pop();
    let exactUrl = url.split("?")[0];
    setUrl(window.location.href.split("/").pop());
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      if (exactUrl === "upload") {
        window.location.href = "/phone";
      } else if (exactUrl === "providers") {
        window.location.href = "/phone";
      } else if (exactUrl === "verify") {
        window.location.href = "/phone";
      } else if (exactUrl === "upload") {
        window.location.href = "/phone";
      } else if (exactUrl === "location") {
        window.location.href = "/phone";
      } else if (exactUrl === "appttype") {
        window.location.href = "/phone";
      } else {
      }
    }
  }, []);

  return (
    <div className="stepper__wrapper">
      {url === "registration" ||
      url === "phone" ||
      url === "completed" ||
      url === "" ? (
        <div></div>
      ) : (
        <div className="stepper_component">
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className="stepper__numbers"
          >
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   )
              // }
              // if (isStepSkipped(index)) {
              //   stepProps.completed = false
              // }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      )}
      <div className="stepper__content__wrapper ">
        <div className="stepper__content ">
          <div className="stepper__content__formFields">
            <BrowserRouter>
              <React.Suspense fallback={"loading"}>
                <Switch>
                  <Route
                    handleNext={handleNext}
                    authed={15}
                    path="/registration"
                    component={ClinicDetails}
                  />
                  <PrivateRoute
                    handleNext={handleNext}
                    authed={0}
                    path="/verify"
                    component={VerifyUser}
                  />
                  <PrivateRoute
                    handleNext={handleNext}
                    authed={1}
                    path="/upload"
                    component={UploadAppointment}
                  />
                  <PrivateRoute
                    handleNext={handleNext}
                    authed={2}
                    path="/providers"
                    component={Providers}
                  />
                  <PrivateRoute
                    handleNext={handleNext}
                    authed={3}
                    path="/location"
                    component={Location}
                  />
                  <PrivateRoute
                    handleNext={handleNext}
                    authed={4}
                    path="/appttype"
                    component={AppointmentType}
                  />
                  <PrivateRoute
                    handleNext={handleNext}
                    authed={55}
                    path="/completed"
                    component={Finish}
                  />
                  <PrivateRoute
                    handleNext={handleNext}
                    authed={6}
                    path="/phone"
                    component={MobileView}
                  />
                  <Redirect from="/" to="/registration" />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </div>
        </div>
      </div>
    </div>
  );
}
