import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

const useStyles = makeStyles(() => ({
  root: {
    color: "#fff",
  },
  btnIcon: {
    color: "#fff",
  },
  default: {
    border: "1px solid red !important",
  },
}));

const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    color: (props) => (props.default ? "#000" : "#fff"),
    padding: "8px 16px",
    lineHeight: 1.5,
    // backgroundColor: '#40E376',
    backgroundColor: (props) => (props.default ? "#ffffff" : "#1ACE7F"),
    border: (props) => (props.default ? "1px solid #d7e0e9" : "none"),
    "&:hover": {
      // backgroundColor: '#3DD06E',
      backgroundColor: (props) => (props.default ? "#F6F6F6" : "#3DD06E"),
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      // backgroundColor: '#3DD06E',
      backgroundColor: (props) => (props.default ? "#F6F6F6" : "#3DD06E"),
    },
    width: (props) => (props.fullWidth ? "100%" : "auto"),
    "[disabled]": {
      backgroundColor: "#cccccc",
      color: "#666666",
      border: "1px solid #999999",
    },
  },
})(Button);

function CustomAddButton(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BootstrapButton
        variant="contained"
        color="primary"
        disableRipple
        startIcon={props.includeIcon ? <AddRoundedIcon /> : null}
        {...props}
        // default
      >
        {props.name ? props.name : null}
      </BootstrapButton>
    </div>
  );
}

export default CustomAddButton;
