import {
  CLINIC_SAVE_RES,
  PROVIDER_SAVE_RES,
  PROVIDER_LIST_RES,
  SAVE_USER_RES,
  USER_LIST_RES,
  USER_UPDATE_RES,
  LOC_SAVE_RES,
  LOC_LIST_RES,
  SPECIALITY_RES,
  APMT_SAVE_RES,
  APMT_LIST_RES,
  SAVE_PRO_LOC_RES,
  PRO_LOC_LIST_RES,
  GET_PRO_SCHEDULE_RES,
  VERIFY_CLINIC_RES,
  FILE_UPLOAD_RES,
  EMR_RES,
  MAIL_TRIGGER_RES,
  PROVIDER_DELETE_RES,
  LOC_DELETE_RES,
  APT_DELETE_RES,
  EMAIL_PHONE_UPDATE_RES,
} from "./reg.types";
/**
 * @fileOverview Manages the response of action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  clinicResponse: null,
  providerResponse: null,
  providerList: null,
  userResponse: null,
  userUpdateResponse: null,
  locResponse: null,
  locList: null,
  userList: null,
  speciality: null,
  apmtResponse: null,
  apmtlistResponse: null,
  proLocationResponse: null,
  prolocationListRes: null,
  proScheduleEdit: null,
  verifyClinicResponse: null,
  fileUploadResponse: null,
  emrResponse: null,
  mailResponse: null,
  providerDeleteResponse: null,
  locDeleteResponse: null,
  aptDeleteResponse: null,
  emailPhoneUpdate: null,
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const regReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLINIC_SAVE_RES: {
      return { ...state, ...{ clinicResponse: action.payload } };
    }
    case PROVIDER_SAVE_RES: {
      return { ...state, ...{ providerResponse: action.payload } };
    }
    case PROVIDER_LIST_RES: {
      return { ...state, ...{ providerList: action.payload } };
    }
    case SAVE_USER_RES: {
      return { ...state, ...{ userResponse: action.payload } };
    }
    case USER_LIST_RES: {
      return { ...state, ...{ userList: action.payload } };
    }
    case USER_UPDATE_RES: {
      return { ...state, ...{ userUpdateResponse: action.payload } };
    }
    case LOC_SAVE_RES: {
      return { ...state, ...{ locResponse: action.payload } };
    }
    case LOC_LIST_RES: {
      return { ...state, ...{ locList: action.payload } };
    }
    case SPECIALITY_RES: {
      return { ...state, ...{ speciality: action.payload } };
    }
    case APMT_SAVE_RES: {
      return { ...state, ...{ apmtResponse: action.payload } };
    }
    case APMT_LIST_RES: {
      return { ...state, ...{ apmtlistResponse: action.payload } };
    }
    case SAVE_PRO_LOC_RES: {
      return { ...state, ...{ proLocationResponse: action.payload } };
    }
    case PRO_LOC_LIST_RES: {
      return { ...state, ...{ prolocationListRes: action.payload } };
    }
    case GET_PRO_SCHEDULE_RES: {
      return { ...state, ...{ proScheduleEdit: action.payload } };
    }
    case VERIFY_CLINIC_RES: {
      return { ...state, ...{ verifyClinicResponse: action.payload } };
    }
    case FILE_UPLOAD_RES: {
      return { ...state, ...{ fileUploadResponse: action.payload } };
    }
    case EMR_RES: {
      return { ...state, ...{ emrResponse: action.payload } };
    }
    case MAIL_TRIGGER_RES: {
      return { ...state, ...{ mailResponse: action.payload } };
    }
    case PROVIDER_DELETE_RES: {
      return { ...state, ...{ providerDeleteResponse: action.payload } };
    }
    case LOC_DELETE_RES: {
      return { ...state, ...{ locDeleteResponse: action.payload } };
    }
    case APT_DELETE_RES: {
      return { ...state, ...{ aptDeleteResponse: action.payload } };
    }
    case EMAIL_PHONE_UPDATE_RES: {
      return { ...state, ...{ emailPhoneUpdate: action.payload } };
    }
    default:
      return state;
  }
};
export default regReducer;
