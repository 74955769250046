import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { proLocList, getproSchedule } from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/CustomButton/CustomButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ProviderLocationForm from "./ProviderLocationForm";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 14,
    "&:hover": {
      color: "#39BD65",
    },
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    color: "#8792a2",
  },
}));

function ProviderLocation(props) {
  const classes = useStyles();

  /* state for 'Add an ProviderLocation' modal */
  const [openModal, setOpenModal] = useState(false);
  const [list, setList] = useState([]);
  const [item, setSelectedItem] = useState(null);
  const handleOpenModal = () => {
    setOpenModal(true);
    setSelectedItem(null);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  /******************************* */
  useEffect(() => {
    props.proLocList();
  }, []);

  useEffect(() => {
    if (
      props.regData.prolocationListRes &&
      props.regData.prolocationListRes.response
    ) {
      const data = props.regData.prolocationListRes.response.data;
      if (data && data.length > 0) {
        setList(data);
      }
    }
  }, [props.regData.prolocationListRes]);

  const handleEdit = (x) => {
    // handleOpenModal()
    // setSelectedItem(x)
    props.getproSchedule(x.providerId);
  };

  useEffect(() => {
    if (
      props.regData.proScheduleEdit &&
      props.regData.proScheduleEdit.response
    ) {
      const data = props.regData.proScheduleEdit.response.data;
      if (data && data.length > 0) {
        handleOpenModal();
        setSelectedItem(data);
      }
    }
  }, [props.regData.proScheduleEdit]);

  return (
    <>
      <div className="container">
        <div className="container__header">
          <Typography variant="h6">Provider Schedule</Typography>
          <CustomButton
            includeIcon={true}
            name="Add New Provider Schedule"
            onClick={handleOpenModal}
          />
        </div>

        <div className="container__listItems p-sm">
          {list &&
            list.map((x) => (
              <div className="container__listItem">
                <div className="container__listItemDetails">
                  <Typography className={classes.title}>
                    {x.providerName}
                  </Typography>
                  <Typography className={classes.subtitle}>
                    {x.locationName}
                  </Typography>
                </div>
                <Typography
                  color="primary"
                  className={classes.editBtn}
                  onClick={() => handleEdit(x)}
                >
                  Edit Details
                </Typography>
              </div>
            ))}
        </div>

        <Dialog open={openModal} maxWidth="lg">
          <div className="p-sm">
            <DialogTitle>Provider Schedule</DialogTitle>
            <DialogContent>
              <ProviderLocationForm
                editValue={item}
                handleClose={handleClose}
              />
            </DialogContent>
          </div>
        </Dialog>
      </div>

      <div className="width-100 center space-between gap-xs forms_margin">
        <CustomButton
          name="Back"
          disabled={props.activeStep === 0}
          onClick={props.handleBack}
          default
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <CustomButton
              onClick={props.handleNext}
              name={
                props.activeStep === props.steps.length - 1 ? "Finish" : "Next"
              }
            />
          </Grid>
        </Grid>
      </div>
      {/* <div className='width-100 center right gap-xs forms_margin'>
				<CustomButton
					name='Back'
					disabled={props.activeStep === 0}
					onClick={props.handleBack}
					default
				/>
				<CustomButton
					onClick={props.handleNext}
					name={props.activeStep === props.steps.length - 1 ? 'Finish' : 'Next'}
				/>
			</div> */}
    </>
  );
}
const mapStateToProps = (state) => ({
  regData: state.reg,
});
const mapDispatchToProps = (dispatch) => ({
  proLocList: () => dispatch(proLocList()),
  getproSchedule: (values) => dispatch(getproSchedule(values)),
});
ProviderLocation.propTypes = {
  proLocList: PropTypes.func,
  getproSchedule: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProviderLocation);
