import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mailTrigger } from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "components/CustomButton/CustomButton";
import "../../GlobalStyles.css";
import "./style.css";

function Finish(props) {
  const [name, setName] = useState("");
  useEffect(() => {
    // props.mailTrigger();
    // setTimeout(() => {
    //   window.location.href = "https://trillium.health/";
    // }, 5000);
    let d = JSON.parse(localStorage.getItem("clinicData"));
    if (d && d.contactName) {
      setName(d.contactName);
    }
  }, []);
  const [iFrame, setiFrame] = useState(false);
  return (
    <div className="finish__stepper__container">
      
      <div>
        <div className="finish_success_page_container">
          <div className="finish_success_page_heading_tag">
            Thank you {localStorage.getItem("name")}!
          </div>

          <div className="main_last_page_content_cr">
            <div className="main_last_page_text_header">
              Registration is now complete
            </div>
            <div className="main_last_page_content">
              <div className="main_last_page_text">
                Before activating your account, we have one final step.
                <span
                  className="click_here_link"
                  onClick={() => setiFrame(true)}
                >
                  Click here{" "}
                </span>
                to pick a time on our calendar for quick training, and you are
                ready to go.
              </div>
              <div className="main_last_page_text">Team trillium.health</div>
            </div>
          </div>
          <div className="verification_page_btns">
            <CustomButton
              className="btn_style_sucess_page"
              name="Schedule Training"
              onClick={() => setiFrame(true)}
            ></CustomButton>
            <CustomButton
              className="btn_style_sucess_page"
              name="Home Page"
              default
              onClick={() =>
                (window.location.href = "https://trillium.health/")
              }
            />
          </div>
        </div>
      </div>
      <Dialog maxWidth="lg" fullWidth={true} open={iFrame}>
        <div className="icon_container_close">
          <CloseIcon
          fontSize="small"
            className="close_icon_iframe"
            onClick={() => setiFrame(false)}
          />
        </div>

        <div className="calendly_popup">
          <iframe src="https://calendly.com/trillium-vineeth/25-min"></iframe>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  mailTrigger: (values) => dispatch(mailTrigger(values)),
});
Finish.propTypes = {
  mailTrigger: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Finish);
