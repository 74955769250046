import { combineReducers } from 'redux'
import regReducer from './Registration/reg.reducer'
/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 */

const rootReducer = combineReducers({
  reg: regReducer,
})

export default rootReducer
