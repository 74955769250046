import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { locList, locDelete } from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/CustomButton/CustomButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import LocationForm from "./LocationForm";
import Grid from "@material-ui/core/Grid";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Chip from "@material-ui/core/Chip";
import { toast } from "react-hot-toast";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./DeleteDialog";

const typeList = [
  { value: 1, name: "Hospital " },
  { value: 2, name: "Nursing Home" },
  { value: 3, name: "Office" },
  { value: 4, name: "Dialysis Center" },
  { value: 5, name: "In-Patient" },
  { value: 6, name: "Rehabilitation Center" },
];
const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 14,
    "&:hover": {
      color: "#39BD65",
    },
  },

  editBtn2: {
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 14,
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    color: "#8792a2",
  },
  errorDiv: {
    color: "#ff0000",
  },
}));

function Location(props) {
  const classes = useStyles();
  const history = useHistory();

  /* state for 'Add a Location' modal */
  const [openModal, setOpenModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [mandatory, setMandatory] = useState(true);
  const [locId, setLocId] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  /******************************* */

  useEffect(() => {
    props.locList();
  }, []);

  useEffect(() => {
    if (props.regData.locList && props.regData.locList.response) {
      const data = props.regData.locList.response.data;
      if (data && data.results.length > 0) {
        setLocations(data.results);
        setMandatory(true);
        data.results.forEach((x) => {
          if (x.addressLine1 && x.city && x.state && x.zip) {
          } else {
            setMandatory(false);
          }
        });
      }
    }
  }, [props.regData.locList]);

  const handleEdit = (x) => {
    handleOpenModal();
    setSelectedLoc(x);
  };
  const handleNext = () => {
    history.push("/appttype");
  };

  useEffect(() => {
    if (
      props.regData.locDeleteResponse &&
      props.regData.locDeleteResponse.response
    ) {
      const response = props.regData.locDeleteResponse.response;
      if (response.responseCode === 0) {
        toast.success("Location deleted successfully");
        let data = response.data;
        let newloc = [];
        locations.forEach((x) => {
          if (x.id !== data.id) {
            newloc.push(x);
          }
        });
        setLocations(newloc);
        setOpenModal(false);
        setDeleteDialog(false);
      }
    }
  }, [props.regData.locDeleteResponse]);
  const handleDelete = (id) => {
    setDeleteDialog(true);
    setLocId(id);
  };
  return (
    <>
      <div className="container">
        <div className="container__header">
          <Typography variant="h6">Clinic Location</Typography>
          <CustomButton
            includeIcon={true}
            name="Add New Location"
            onClick={handleOpenModal}
          />
        </div>

        <div className="container__listItems p-sm">
          {locations &&
            locations.map((x) => (
              <div className="container__listItem">
                <div className="container__listItemDetails">
                  <Typography className={classes.title}>
                    {x.locationName}
                  </Typography>
                  <Typography className={classes.subtitle}>
                    {
                      typeList.find(
                        (element) => element.value === x.locationType
                      ).name
                    }
                  </Typography>
                </div>
                <div className="icon_delete_verify">
                  {x.addressLine1 && x.city && x.state && x.zip ? (
                    <Chip
                      icon={<VerifiedUserIcon />}
                      label="Edit"
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(x)}
                    />
                  ) : (
                    <Chip
                      label="Verify"
                      size="small"
                      color="secondary"
                      onClick={() => handleEdit(x)}
                    />
                  )}
                  <div
                    title="Delete"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(x.id)}
                  >
                    <DeleteIcon color="secondary" />
                  </div>
                </div>
              </div>
            ))}
        </div>

        <Dialog open={openModal} maxWidth="lg">
          <div className="p-sm">
            <DialogTitle>Add a Clinic Location</DialogTitle>
            <DialogContent>
              <LocationForm editValue={selectedLoc} handleClose={handleClose} />
            </DialogContent>
          </div>
        </Dialog>
      </div>
      <div className="width-100 center space-between gap-xs forms_margin">
        <CustomButton
          name="Back"
          onClick={() => history.push("/providers")}
          default
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <CustomButton
              disabled={!mandatory}
              onClick={handleNext}
              name={"Next"}
            />
          </Grid>
        </Grid>
      </div>
      <DeleteDialog
        deleteDialog={deleteDialog}
        handleCloseDelete={() => setDeleteDialog(false)}
        triggerDelete={() => props.locDelete(locId)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  locList: () => dispatch(locList()),
  locDelete: (values) => dispatch(locDelete(values)),
});
Location.propTypes = {
  locList: PropTypes.func,
  locDelete: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Location);
