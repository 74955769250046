import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import environment from "../../environments/environment";
import {
  CLINIC_SAVE,
  REQUEST_CANCEL,
  PROVIDER_SAVE,
  PROVIDER_LIST,
  SAVE_USER,
  USER_LIST,
  USER_UPDATE,
  LOC_SAVE,
  LOC_LIST,
  SPECIALITY,
  APMT_LIST,
  APMT_SAVE,
  SAVE_PRO_LOC,
  PRO_LOC_LIST,
  GET_PRO_SCHEDULE,
  VERIFY_CLINIC,
  FILE_UPLOAD,
  EMR,
  MAIL_TRIGGER,
  PROVIDER_DELETE,
  LOC_DELETE,
  APT_DELETE,
  EMAIL_PHONE_UPDATE,
} from "./reg.types";
import {
  clinicSaveResponse,
  providerSaveResponse,
  providerListResponse,
  userSaveResponse,
  userListResponse,
  userUpdateResponse,
  locSaveResponse,
  locListResponse,
  specialityResponse,
  apmtListResponse,
  apmtSaveResponse,
  proLocSaveRes,
  proLocListResponse,
  getproScheduleRes,
  verifyClinicRes,
  fileUploadResponse,
  emrResponse,
  mailTriggerResponse,
  providerDeleteResponse,
  locDeleteResponse,
  aptDeleteResponse,
  emailPhoneUpdateRes,
} from "./reg.actions";
// import { Apis } from 'Redux/APIs'
const { baseUrl } = environment;
const RegistrationEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
RegistrationEpic.clinicSaveApi = (action$) =>
  action$.pipe(
    ofType(CLINIC_SAVE),
    switchMap((action) =>
      ajax({
        headers: {
          Authorization: '""',
          "Content-Type": "application/json",
        },
        url: `${baseUrl}/trillium-clinic-service/v1/clinic/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => clinicSaveResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.providerSaveApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/provider/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => providerSaveResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.providerListApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_LIST),
    switchMap(() =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/provider/list/all?start=0&limit=100`,
        method: "GET",
      }).pipe(
        map(
          (response) => providerListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.userSaveApi = (action$) =>
  action$.pipe(
    ofType(SAVE_USER),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/user/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => userSaveResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.userUpdateApi = (action$) =>
  action$.pipe(
    ofType(USER_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/user/${action.payload.userId}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => userUpdateResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RegistrationEpic.userListApi = (action$) =>
  action$.pipe(
    ofType(USER_LIST),
    switchMap(() =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/user/list/range?start=0&limit=100`,
        method: "GET",
      }).pipe(
        map(
          (response) => userListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RegistrationEpic.locSaveApi = (action$) =>
  action$.pipe(
    ofType(LOC_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/location/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => locSaveResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RegistrationEpic.locListApi = (action$) =>
  action$.pipe(
    ofType(LOC_LIST),
    switchMap(() =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/location/list/all?start=0&limit=100`,
        method: "GET",
      }).pipe(
        map(
          (response) => locListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RegistrationEpic.specialityApi = (action$) =>
  action$.pipe(
    ofType(SPECIALITY),
    switchMap(() =>
      ajax({
        url: `${baseUrl}/trillium-clinic-service/v1/speacility/search`,
        method: "GET",
      }).pipe(
        map(
          (response) => specialityResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RegistrationEpic.apmtlistApi = (action$) =>
  action$.pipe(
    ofType(APMT_LIST),
    switchMap(() =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/appointmentType/list/all?start=0&limit=100`,
        method: "GET",
      }).pipe(
        map(
          (response) => apmtListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.apmtSaveApi = (action$) =>
  action$.pipe(
    ofType(APMT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/appointmentType/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => apmtSaveResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RegistrationEpic.proLocSaveApi = (action$) =>
  action$.pipe(
    ofType(SAVE_PRO_LOC),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/providerlocation/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => proLocSaveRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.proLocListApi = (action$) =>
  action$.pipe(
    ofType(PRO_LOC_LIST),
    switchMap(() =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/providerlocation/list/all?start=0&limit=100`,
        method: "GET",
      }).pipe(
        map(
          (response) => proLocListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RegistrationEpic.getproScheduleApi = (action$) =>
  action$.pipe(
    ofType(GET_PRO_SCHEDULE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/providerlocation/list/all/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getproScheduleRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.verifyClinicApi = (action$) =>
  action$.pipe(
    ofType(VERIFY_CLINIC),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/user/credential/verify`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => verifyClinicRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

RegistrationEpic.fileUpload = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          "application-id": 2,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        url: `${baseUrl}/trillium-clinic-service/v1/clinic/excel/upload?guid=10&emrValue=${action.payload.type}&emrName=${action.payload.name}`,
        method: "POST",
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
RegistrationEpic.emrApi = (action$) =>
  action$.pipe(
    ofType(EMR),
    switchMap(() =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/emrservice/list`,
        method: "GET",
      }).pipe(
        map(
          (response) => emrResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
RegistrationEpic.mailTriggerApi = (action$) =>
  action$.pipe(
    ofType(MAIL_TRIGGER),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/clinic/mail/regcompleted`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => mailTriggerResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
RegistrationEpic.providerDeleteApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/provider/${action.payload}`,
        method: "DELETE",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => providerDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
RegistrationEpic.locDeleteApi = (action$) =>
  action$.pipe(
    ofType(LOC_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/location/${action.payload}`,
        method: "DELETE",
      }).pipe(
        map(
          (response) => locDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
RegistrationEpic.aptDeleteApi = (action$) =>
  action$.pipe(
    ofType(APT_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/appointmentType/${action.payload}`,
        method: "DELETE",
      }).pipe(
        map(
          (response) => aptDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
RegistrationEpic.emailPhoneUpdateApi = (action$) =>
  action$.pipe(
    ofType(EMAIL_PHONE_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/clinic/change/`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => emailPhoneUpdateRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
export default RegistrationEpic;
