import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fileUpload, getEmr } from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FileUploaderDropzone from "components/FileUploaderDropzone/FileUploaderDropzone";
import Grid from "@material-ui/core/Grid";
import CustomCircleLoader from "components/CircleLoader/CircleLoader";
import CustomButton from "components/CustomButton/CustomButton";
import TickIcon from "../../../assets/tick-icon.svg";
import UploadErrorIcon from "../../../assets/upload-error.svg";
import "../../../GlobalStyles.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 500,
  },
});

function UploadAppointment(props) {
  const [emrArray, setEmrArray] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("none");
  const classes = useStyles();
  const history = useHistory();

  const fileUploadfn = (fileData, type, emrName) => {
    const data = new FormData();
    data.append("file", fileData[0]);
    props.fileUpload({ data: data, type: type, name: emrName });
    setUploadStatus("uploading");
  };

  useEffect(() => {
    if (
      props.regData.fileUploadResponse &&
      props.regData.fileUploadResponse.response
    ) {
      const response = props.regData.fileUploadResponse.response;
      if (response.responseCode === 0) {
        setUploadStatus("success");
      }
      if (response.responseCode !== 0) {
        setUploadStatus("failed");
      }
    }
  }, [props.regData.fileUploadResponse]);

  useEffect(() => {
    props.getEmr();
  }, []);

  useEffect(() => {
    if (props.regData.emrResponse && props.regData.emrResponse.response) {
      const response = props.regData.emrResponse.response;
      if (response.responseCode === 0) {
        let emrResponse = [];
        response.data &&
          response.data.forEach((x) => {
            emrResponse.push({
              name: x.description,
              value: x.value,
            });
          });
        setEmrArray(emrResponse);
      }
    }
  }, [props.regData.emrResponse]);

  return (
    <div className="stepper__container">
      {uploadStatus === "none" && (
        <>
          <div className="top gap-xs center-column">
            <Typography variant="h6">Upload Sample Appointment File</Typography>

            <div className="center ">
              <Typography variant="subtitle1">
                Export your list of appointments from your scheduler and upload
                it here. If you are using eClinicalWorks, choose eBO Report 4.02
              </Typography>
              {/* <span className="link">
            <a href="#">Check out how to do this</a>
          </span> */}
            </div>
          </div>
          <FileUploaderDropzone
            fileUploadfn={fileUploadfn}
            emrArray={emrArray}
          />
        </>
      )}
      {uploadStatus === "uploading" && (
        <div className="loaderDiv">
          <div className="center center-column">
            <CustomCircleLoader />
            <Typography variant="h6">Uploading ...</Typography>
          </div>

          {/* <div className="center center-column">
          <CustomCircleLoader />
          <Typography variant="h6">Extracting ...</Typography>
        </div>
        <div className="center center-column">
          <CustomCircleLoader />
          <Typography variant="h6">Analysing ...</Typography>
        </div> */}
        </div>
      )}

      <div>
        {uploadStatus === "success" && (
          <div className="center center-column">
            <img src={TickIcon} alt="" />
            <Typography variant="h6">{"Upload successful"}</Typography>
            <Typography variant="subtitle1" className="text-grey">
              {"Sample appointment file has been uploaded"}
            </Typography>
          </div>
        )}
        {uploadStatus === "failed" && (
          <div className="center center-column">
            <img src={UploadErrorIcon} alt="" />
            <Typography variant="h6">{"Upload failed"}</Typography>
            <Typography variant="subtitle1" className="text-grey">
              {"Your appointment upload has failed. "}
            </Typography>
            <Typography variant="subtitle1" className="text-grey">
              Click <b className="text-black">'Try Again'</b> to try again or{" "}
              <b className="text-black">'Skip'</b> to continue your registration
              without uploading.
            </Typography>
            <span className="link">
              <a href="#">Check out how to do this</a>
            </span>
          </div>
        )}
      </div>

      <div className="width-100 center space-between gap-xs">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            {uploadStatus === "failed" && (
              <CustomButton
                name="Try Again"
                onClick={() => setUploadStatus("none")}
                default
              />
            )}
          </Grid>
          <Grid item>
            {uploadStatus !== "success" && (
              <CustomButton
                name="Skip"
                onClick={() => history.push("/providers")}
                default
              />
            )}
          </Grid>
          <Grid item>
            {uploadStatus === "success" && (
              <CustomButton
                name={"Next"}
                onClick={() => history.push("/providers")}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  fileUpload: (values) => dispatch(fileUpload(values)),
  getEmr: (values) => dispatch(getEmr(values)),
});
UploadAppointment.propTypes = {
  fileUpload: PropTypes.func,
  getEmr: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadAppointment);
