import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  locSave,
  locSaveResponse,
  locList,
  locDelete,
} from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Input from "components/Input";
import PhoneFormat from "components/PhoneFormat";
import CustomButton from "components/CustomButton/CustomButton";
import Dropdown from "components/Dropdown";
import DeleteIcon from "@material-ui/icons/Delete";
import states from "HelperFunctions/states.json";
import { useForm } from "Hooks/validator";
import { useState } from "react";
import DeleteDialog from "./DeleteDialog";

const useStyles = makeStyles((theme) => ({
  accordion: {
    margin: "16px 0",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid var(--line-grey)",
    "&:before": {
      display: "none",
    },
  },
  accordionContent: {
    padding: "16px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const typeList = [
  { value: 1, name: "Hospital " },
  { value: 2, name: "Nursing Home" },
  { value: 3, name: "Office" },
  { value: 4, name: "Dialysis Center" },
  { value: 5, name: "In-Patient" },
  { value: 6, name: "Rehabilitation Center" },
];

function LocationForm(props) {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const classes = useStyles();
  const defaultValues = {
    active: 1,
    clinicId: localStorage.getItem("cid"),
    addressLine1: "",
    addressLine2: "",
    city: "",
    id: 0,
    fax: "",
    locationName: "",
    locationType: 3,
    phone: "",
    state: "AL",
    zip: "",
    contactName: "",
  };

  const customErrorAttribute = {
    className: "has-error",
    "another-attr": "look-at-me",
    error: true,
    helperText: "This field is required",
  };

  const { values, setValues, isValid, useInput, setIsResetform } = useForm(
    defaultValues,
    customErrorAttribute
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      props.locSave(values);
    }
  };

  useEffect(() => {
    if (props.regData.locResponse && props.regData.locResponse.response) {
      const response = props.regData.locResponse.response;
      if (response.responseCode === 0) {
        props.handleClose();
        if (values.id === 0) {
          toast.success("Location added successfully");
        } else {
          toast.success("Location updated successfully");
        }
        props.locSaveResponse(null);
        props.locList();
      }
    }
  }, [props.regData.locResponse]);

  useEffect(() => {
    if (props.editValue) {
      setValues({ ...values, ...props.editValue });
    }
  }, [props.editValue]);

  useEffect(() => {
    if (
      props.regData.locDeleteResponse &&
      props.regData.locDeleteResponse.response
    ) {
      const response = props.regData.locDeleteResponse.response;
      if (response.responseCode === 0) {
        setDeleteDialog(false);
      }
    }
  }, [props.regData.locDeleteResponse]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Input
              required
              id={"name"}
              tabIndex={1}
              type="text"
              label="Name"
              {...useInput("locationName", {
                isRequired: {
                  value: true,
                  message: "Enter a valid Name",
                },
                isLength: {
                  value: { max: 100 },
                  message: "Max Length is 100",
                },
                // matches: {
                //   value: /^[a-zA-Z][a-zA-Z -]+$/,
                //   message: 'Enter valid Name',
                // },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              tabIndex={3}
              options={typeList}
              label="Type"
              {...useInput("locationType", {
                isRequired: {
                  value: true,
                  message: "Please select a type",
                },
              })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Input
              required
              id={"addressLine1"}
              tabIndex={2}
              type="text"
              label="Address Line 1"
              {...useInput("addressLine1", {
                isRequired: {
                  value: true,
                  message: "Enter a valid address",
                },
                isLength: {
                  value: { max: 100 },
                  message: "Max Length is 100",
                },
                // matches: {
                //   value: /^[a-zA-Z0-9][a-zA-Z0-9 -]+$/,
                //   message:
                //     "Please enter the valid address with atleast one character",
                // },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              id={"addressLine2"}
              tabIndex={3}
              label="Address Line 2"
              {...useInput("addressLine2", {
                // matches: {
                //   value: /^[a-zA-Z0-9][a-zA-Z0-9 -]+$/,
                //   message:
                //     "Please enter the valid address with atleast one character",
                // },
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Input
              id={"city1"}
              tabIndex={19}
              type="text"
              label="City"
              {...useInput("city", {
                isRequired: {
                  value: true,
                  message: "Enter a valid City",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              tabIndex={3}
              options={states}
              label="State"
              {...useInput("state", {})}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              required
              id={"zips"}
              tabIndex={19}
              type="text"
              label="Zip"
              inputProps={{
                maxLength: 10,
              }}
              {...useInput("zip", {
                matches: {
                  // value: /(^\d{5}$)|(^\d{5}-)/,
                  value: /^[0-9][0-9 -]+$/,
                  message: "Enter a valid Zip",
                },
                isRequired: {
                  value: true,
                  message: "Enter a valid Zip",
                },
              })}
            />
          </Grid>
        </Grid>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1">
              Other Information (Optional)
            </Typography>
          </AccordionSummary>
          <div className={classes.accordionContent}>
            <Grid container spacing={5} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <Input
                  id={"cPerson"}
                  tabIndex={2}
                  type="text"
                  label="Contact Person"
                  {...useInput("contactName", {
                    // isRequired: {
                    // 	value: true,
                    // 	message: 'Enter a valid Name',
                    // },
                    isLength: {
                      value: { max: 100 },
                      message: "Max Length is 100",
                    },
                    matches: {
                      value: /^[a-zA-Z][a-zA-Z -]+$/,
                      message: "Enter valid Name",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneFormat
                  id={"phone"}
                  tabIndex={13}
                  type="text"
                  label="Phone"
                  placeholder="(555) 555-5555"
                  {...useInput("phone", {
                    matches: {
                      value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                      message: "Enter valid phone number",
                    },
                    // isRequired: {
                    // 	value: true,
                    // 	message: 'Enter valid phone number',
                    // },
                  })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <PhoneFormat
                  label="Fax"
                  {...useInput("fax", {
                    matches: {
                      value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                      message: "Enter a valid Fax",
                    },
                    // isRequired: {
                    // 	value: true,
                    // 	message: 'Enter a valid Fax',
                    // },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input id={"alias"} tabIndex={2} type="text" label="Alias" />
              </Grid>
            </Grid>
          </div>
        </Accordion>

        <div className="form__btnWrapper">
          {values.id ? (
            <CustomButton
              name="Delete"
              onClick={() => setDeleteDialog(true)}
              default
            />
          ) : null}

          <CustomButton
            name="Cancel"
            onClick={() => props.handleClose()}
            default
          />
          <CustomButton name="Submit" type="submit" />
        </div>
      </form>
      <DeleteDialog
        deleteDialog={deleteDialog}
        handleCloseDelete={() => setDeleteDialog(false)}
        triggerDelete={() => props.locDelete(values.id)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  locSave: (values) => dispatch(locSave(values)),
  locSaveResponse: (values) => dispatch(locSaveResponse(values)),
  locList: () => dispatch(locList()),
  locDelete: (values) => dispatch(locDelete(values)),
});
LocationForm.propTypes = {
  locSave: PropTypes.func,
  locSaveResponse: PropTypes.func,
  locList: PropTypes.func,
  locDelete: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
