import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  apmtSave,
  apmtSaveResponse,
  apmtList,
  aptDelete,
} from "Redux/Registration/reg.actions";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Input from "components/Input";
import CustomButton from "components/CustomButton/CustomButton";
import Dropdown from "components/Dropdown";
import { useForm } from "Hooks/validator";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./DeleteDialog";
import { useState } from "react";
const patientRequired = [
  { value: 1, name: "Yes" },
  { value: 2, name: "No" },
];

const Interval = [
  { value: 30, name: "30 min" },
  { value: 15, name: "15 min" },
];

function AppointmentTypeForm(props) {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const defaultValues = {
    active: 0,
    color: "",
    defaults: 0,
    duration: 15,
    favourite: true,
    id: 0,
    name: "",
    patientRequired: 1,
    systemType: 0,
    clinicId: localStorage.getItem("cid"),
  };
  const customErrorAttribute = {
    className: "has-error",
    "another-attr": "look-at-me",
    error: true,
    helperText: "This field is required",
  };

  const { values, setValues, isValid, useInput, setIsResetform } = useForm(
    defaultValues,
    customErrorAttribute
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    props.apmtSave({
      ...values,
      patientRequired: values.patientRequired === 1 ? true : false,
    });
  };

  useEffect(() => {
    if (props.regData.apmtResponse && props.regData.apmtResponse.response) {
      const response = props.regData.apmtResponse.response;
      if (response.responseCode === 0) {
        props.handleClose();
        if (values.id === 0) {
          toast.success("Appointment type added successfully");
        } else {
          toast.success("Appointment type updated successfully");
        }
        props.apmtSaveResponse(null);
        props.apmtList();
      }
    }
  }, [props.regData.apmtResponse]);

  useEffect(() => {
    if (props.editValue) {
      setValues({
        ...props.editValue,
        patientRequired: props.editValue.patientRequired === true ? 1 : 2,
      });
    }
  }, [props.editValue]);

  useEffect(() => {
    if (
      props.regData.aptDeleteResponse &&
      props.regData.aptDeleteResponse.response
    ) {
      const response = props.regData.aptDeleteResponse.response;
      if (response.responseCode === 0) {
        setDeleteDialog(false);
      }
    }
  }, [props.regData.aptDeleteResponse]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12} sm={4} style={{ width: "700px" }}>
            <Input
              required
              id={"name"}
              tabIndex={1}
              type="text"
              label="Appointment Type"
              {...useInput("name", {
                isRequired: {
                  value: true,
                  message: "Enter a valid name",
                },
                isLength: {
                  value: { max: 100 },
                  message: "Max Length is 15",
                },
                // matches: {
                //   value: /^[a-zA-Z][a-zA-Z -]+$/,
                //   message: 'Enter the valid name',
                // },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              required
              id={"role"}
              tabIndex={3}
              options={patientRequired}
              label="Patient Required"
              {...useInput("patientRequired", {
                isRequired: {
                  value: true,
                  message: "Please select a role",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              required
              id={"role"}
              tabIndex={3}
              options={Interval}
              label="Default Interval"
              {...useInput("duration", {
                isRequired: {
                  value: true,
                  message: "Please select a role",
                },
              })}
            />
          </Grid>
        </Grid>

        <div className="form__btnWrapper">
          {values.id ? (
            <CustomButton
              name="Delete"
              onClick={() => setDeleteDialog(true)}
              default
            />
          ) : null}
          <CustomButton
            name="Cancel"
            onClick={() => props.handleClose()}
            default
          />
          <CustomButton
            name="Submit"
            type="submit"
            // onClick={handleSubmit}
          />
        </div>
      </form>
      <DeleteDialog
        deleteDialog={deleteDialog}
        handleCloseDelete={() => setDeleteDialog(false)}
        triggerDelete={() => props.aptDelete(values.id)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  apmtSave: (values) => dispatch(apmtSave(values)),
  apmtSaveResponse: (values) => dispatch(apmtSaveResponse(values)),
  apmtList: (values) => dispatch(apmtList(values)),
  aptDelete: (values) => dispatch(aptDelete(values)),
});
AppointmentTypeForm.propTypes = {
  apmtSave: PropTypes.func,
  apmtSaveResponse: PropTypes.func,
  apmtList: PropTypes.func,
  aptDelete: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentTypeForm);
