import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  clinicSave,
  clinicSaveResponse,
  getSpeciality,
  verifyClinic,
} from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import { isValidNpi } from "HelperFunctions/npi";
import Grid from "@material-ui/core/Grid";
import Input from "components/Input";
import PhoneFormat from "components/PhoneFormat";
import CustomButton from "components/CustomButton/CustomButton";
import Dropdown from "components/Dropdown";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "Hooks/validator";
import states from "HelperFunctions/states.json";
import CustomCircleLoader from "components/CircleLoader/CircleLoader";
import toast from "react-hot-toast";
import "../../../GlobalStyles.css";
import "./style.css";

const useStyles = makeStyles({
  dialogButton: {
    display: "block",
    padding: "8px 24px",
  },
});

function ClinicDetails(props) {
  const [speciality, setSpeciality] = useState([]);
  const [npiValid, setnpiValid] = useState(false);
  const [formStatus, setFormStatus] = React.useState("register");

  const defaultValues = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    clinicId: 0,
    contactName: "",
    contactPhone: "",
    email: "",
    fax: "",
    federalTaxId: "",
    groupNpi: "",
    name: "",
    phone: "",
    specialityCode: "",
    state: "AL",
    taxonomy: "",
    zip: "",
  };
  const customErrorAttribute = {
    error: true,
    helperText: "value is required",
  };
  const { useInput, values, setValues, isValid } = useForm(
    defaultValues,
    customErrorAttribute
  );

  /* Email and Phone verification main dialogue function */

  // const handleClickOpen = (e) => {
  // 	e.preventDefault();
  // 	setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  /* ********************************************/

  const handleSubmit = (e) => {
    e.preventDefault();
    //  localStorage.setItem("clinicData",JSON.stringify(values))
    if (values.clinicId === 0 && isValid && npiValid === false) {
      setFormStatus("loading");
      props.clinicSave({
        active: 1,
        addressLine1: values.addressLine1 ? values.addressLine1 : "",
        addressLine2: values.addressLine2 ? values.addressLine2 : "",
        city: values.city ? values.city : "",
        clinicId: values.clinicId ? values.clinicId : "",
        contactName: values.contactName ? values.contactName : "",
        contactPhone: values.contactPhone ? values.contactPhone : "",
        country: "",
        email: values.email ? values.email : "",
        fax: "",
        federalTaxId: values.federalTaxId ? values.federalTaxId : "",
        groupNpi: values.groupNpi ? values.groupNpi : "",
        name: values.name ? values.name : "",
        phone: values.phone ? values.phone : "",
        specialityCode: speciality.find((x) => x.value === values.taxonomy)
          .name,
        state: values.state ? values.state : "",
        taxonomy: values.taxonomy ? values.taxonomy : "",
        zip: values.zip ? values.zip : "",
      });
    } else {
      // history.push("/upload");
    }
  };

  useEffect(() => {
    props.getSpeciality();
    let data = JSON.parse(localStorage.getItem("clinicData"));
    if (data) {
      // setValues(data);
    }
  }, []);

  useEffect(() => {
    if (
      props.regData &&
      props.regData.clinicResponse &&
      props.regData.clinicResponse.response
    ) {
      let data = props.regData.clinicResponse.response.data;
      if (data && data.accessToken) {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("cid", data.clinicId);
        localStorage.setItem(
          "clinicData",
          JSON.stringify({ ...values, clinicId: data.clinicId })
        );
        let name = values.contactName.split(" ")[0];
        localStorage.setItem("name", name);
        props.clinicSaveResponse({ ...values, clinicId: data.clinicId });
        setFormStatus("success");

        // localStorage.clear();
      } else {
        toast.error("Clinic registration failed!");
        setFormStatus("register");
      }
    }
  }, [props.regData.clinicResponse]);

  useEffect(() => {
    if (props.regData.speciality && props.regData.speciality.response) {
      const response = props.regData.speciality.response;
      if (response.responseCode === 0) {
        let specaility = [];
        response.data &&
          response.data.forEach((x) => {
            specaility.push({
              name: x.value,
              value: x.code,
            });
          });
        setSpeciality(specaility);
      }
    }
  }, [props.regData.speciality]);

  const validateNpi = (e) => {
    if (e.target.value) {
      if (isValidNpi(e.target.value)) {
        setnpiValid(false);
      } else {
        setnpiValid(true);
      }
    }
  };

  const handleNpi = (e) => {
    setValues({ ...values, groupNpi: e.target.value });
  };

  return (
    <div className="clinicDetails__stepper__container">
      <div hidden={formStatus === "register" ? false : true}>
        <p className="clinicDetails_heading_tag">
          Enter your details to Register
        </p>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction={"row"}
            spacing={3}
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={6}>
              <Input
                label="Clinic Name"
                required
                {...useInput("name", {
                  isRequired: {
                    value: true,
                    message: "Enter a valid Name",
                  },
                  isLength: {
                    value: { max: 100 },
                    message: "Max Length is 100",
                  },
                  matches: {
                    value: /^[a-zA-Z][a-zA-Z -]+$/,
                    message: "Enter valid Name",
                  },
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                required
                id={"rodfgdfgle"}
                tabIndex={3}
                options={speciality}
                label="Speciality"
                {...useInput("taxonomy", {
                  isRequired: {
                    value: true,
                    message: "Please select a speciality",
                  },
                })}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <Input
                required
                label="Address Line 1"
                {...useInput("addressLine1", {
                  isRequired: {
                    value: true,
                    message: "Enter a valid address",
                  },
                  isLength: {
                    value: { max: 100 },
                    message: "Max Length is 100",
                  },
                  // matches: {
                  //   value: /^[a-zA-Z0-9][a-zA-Z0-9 -]+$/,
                  //   message:
                  //     "Please enter the valid address with atleast one character",
                  // },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label="Address Line 2"
                {...useInput("addressLine2", {
                  // matches: {
                  //   value: /^[a-zA-Z0-9][a-zA-Z0-9 -]+$/,
                  //   message:
                  //     "Please enter the valid address with atleast one character",
                  // },
                })}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
              <Input
                required
                id={"city1"}
                tabIndex={19}
                type="text"
                label="City"
                {...useInput("city", {
                  isRequired: {
                    value: true,
                    message: "Enter a valid City",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                required
                options={states}
                label="State"
                {...useInput("state", {})}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                required
                id={"zips"}
                tabIndex={19}
                type="text"
                label="Zip"
                inputProps={{
                  maxLength: 10,
                }}
                {...useInput("zip", {
                  matches: {
                    // value: /(^\d{5}$)|(^\d{5}-)/,
                    value: /^[0-9][0-9 -]+$/,
                    message: "Enter a valid Zip",
                  },
                  isRequired: {
                    value: true,
                    message: "Enter a valid Zip",
                  },
                })}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
              <PhoneFormat
                required
                label="Clinic Main Phone"
                {...useInput("phone", {
                  isRequired: {
                    value: true,
                    message: "Enter a valid phone",
                  },
                  matches: {
                    value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                    message: "Enter a valid phone number",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                required
                label="Federal Tax ID"
                {...useInput("federalTaxId", {
                  matches: {
                    value: /^[0-9][0-9 -]+$/,
                    message: "Enter a valid TaxId",
                  },
                  isLength: {
                    value: { max: 15 },
                    message: "Max Length is 15",
                  },
                  isRequired: {
                    value: true,
                    message: "Enter a valid TaxId",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                required
                label="Group NPI"
                onBlur={validateNpi}
                error={npiValid}
                helperText={npiValid ? "Enter a valid NPI" : ""}
                onChange={handleNpi}
                value={values.groupNpi}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
              <Input
                required
                label="Your Name"
                {...useInput("contactName", {
                  matches: {
                    value: /^[a-zA-Z][a-zA-Z -]+$/,
                    message: "Enter valid Name",
                  },
                  isRequired: {
                    value: true,
                    message: "Enter a valid Name",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PhoneFormat
                required
                helperText="We will send your password to this number"
                label="Your Contact Phone"
                {...useInput("contactPhone", {
                  matches: {
                    value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                    message: "Enter a valid phone number",
                  },
                  isRequired: {
                    value: true,
                    message: "Enter a valid Phone",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                required
                id={"email"}
                tabIndex={15}
                type="text"
                helperText="We will send your username to this email"
                label="Your Email"
                {...useInput("email", {
                  isRequired: {
                    value: true,
                    message: "Enter a valid email",
                  },
                  // isLength: {
                  //   value: { max: 45 },
                  //   message: 'Maximum length is 45',
                  // },
                  matches: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Enter a valid email",
                  },
                })}
              />
            </Grid>
          </Grid>

          <div className="width-100 center right gap-xs forms_margin">
            <CustomButton type="submit" name={"Register"} />
          </div>
        </form>

        {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="p-sm center center-column">
            <img src={TickIcon} alt="" />
            <DialogTitle
              id="alert-dialog-title"
              className="text-center"
              style={{ maxWidth: "300px" }}
              disableTypography
            >
              <Typography variant="h6" gutterBottom>
                Username and Password have been shared to your given Phone
                Number & Email
              </Typography>
            </DialogTitle>
          </div>
        </Dialog> */}
      </div>
      <div
        className="clinicDetails_sucess_page_registration"
        hidden={formStatus === "success" ? false : true}
      >
        <div className="main_success_page_container">
          <div className="main_success_page_heading_tag">
            Thank you, {localStorage.getItem("name")}!
          </div>

          <div className="main_success_page_content_cr">
            <div className="main_success_page_content">
              <div className="main_success_page_text">
                We have emailed the username. We have sent your password to the
                registered phone number.Please keep your login information in a
                safe place for later use.
              </div>
            </div>
            <div className="main_success_page_content">
              <div className="main_success_page_text">
                If you did not receive the confirmation email, please check your
                SPAM folder
              </div>
            </div>
            <div className="main_success_page_content">
              <div className="main_success_page_text">
                Next, please open your email and click on the verification link.
              </div>
            </div>
            <div className="verification_page_btns">
              <CustomButton
                className="btn_style_sucess_page"
                name="Verification Page"
                onClick={() => (window.location.href = "/verify")}
              ></CustomButton>
              <CustomButton
                className="btn_style_sucess_page"
                name="Home Page"
                default
                onClick={() =>
                  (window.location.href = "https://trillium.health/")
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={"loaderDiv"}
        hidden={formStatus === "loading" ? false : true}
      >
        <div className="center center-column">
          <CustomCircleLoader />
          <Typography variant="h6">Processing...</Typography>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  clinicSave: (values) => dispatch(clinicSave(values)),
  clinicSaveResponse: (values) => dispatch(clinicSaveResponse(values)),
  getSpeciality: (values) => dispatch(getSpeciality(values)),
  verifyClinic: (values) => dispatch(verifyClinic(values)),
});
ClinicDetails.propTypes = {
  clinicSave: PropTypes.func,
  clinicSaveResponse: PropTypes.func,
  getSpeciality: PropTypes.func,
  verifyClinic: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClinicDetails);
